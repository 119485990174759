import { asText } from '~/components/Abstracts/Storyblok'
import { PushSliderProps } from '~/components/Slices/PushSlider'

import serializeCta from '~/data/serialize-cta'
import serializeImage from '~/data/serialize-image'
import serializeLink from '~/data/serialize-link'

export const MAGAZINE_ITEM_LAYOUT = {
  DEFAULT: 'default',
  ROUND: 'round',
  OVAL: 'oval',
  SQUARE: 'square',
} as const

export default async function serializePushSlider(
  slice,
  locale,
): Promise<PushSliderProps> {
  return {
    backgroundColor: slice?.background_color?.color ?? '#FFFFFF',
    title: asText(slice?.title) ?? null,
    cta: serializeCta(locale, slice?.cta) ?? null,
    media:
      slice?.media
        ?.map?.((mediaWithLink) => {
          const mediaComponent = mediaWithLink?.media?.[0]
          const mediaType = mediaComponent?.video_url ? 'video' : 'image'
          const hasLink =
            mediaWithLink?.link?.id?.length > 0 ||
            mediaWithLink?.link?.url?.length > 0
          return {
            mediaType,
            link: hasLink
              ? serializeLink(mediaWithLink?.link, locale)
              : null ?? null,
            mediaProps:
              mediaType === 'image'
                ? serializeImage(mediaComponent?.image)
                : {
                    src: mediaComponent.video_url ?? null,
                    poster: serializeImage(mediaComponent?.image)?.src ?? null,
                  },
          }
        })
        ?.filter((mediaWithLink) => {
          return mediaWithLink?.mediaProps?.src
        }) ?? null,
    items: slice?.items?.map((item) => ({
      layout: item?.layout ?? MAGAZINE_ITEM_LAYOUT.DEFAULT,
      image: serializeImage(item?.image) ?? null,
      tag: item?.tag ?? null,
      title: item?.title ?? null,
      subtitle: item?.subtitle ?? null,
      cta: serializeCta(locale, item?.cta) ?? null,
    })),
  }
}
