import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import ImageWithCaption, {
  ImageWithCaptionProps,
} from '~/components/UI/ImageWithCaption'

import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialTwoColumnsImageTextProps {
  className?: string
  isReversed?: boolean
  imageWithCaption?: ImageWithCaptionProps
  text?: RichTextBlock
}

function EditorialTwoColumnsImageText({
  className,
  isReversed,
  imageWithCaption,
  text,
}: EditorialTwoColumnsImageTextProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const textStyle = useStyle({ textPreset: GlobalTextPreset.RichTextEditorial })
  const isFirstSlice = useIsFirstSlice()
  return (
    <div
      className={cx(css.EditorialTwoColumnsImageText, className, {
        isReversed,
      })}>
      <div className={cx(gridStyle, css.content)}>
        {imageWithCaption && (
          <ImageWithCaption
            {...{
              ...imageWithCaption,
              image: { ...imageWithCaption?.image, priority: isFirstSlice },
            }}
            className={css.image}
          />
        )}
        {text && <RichText render={text} className={cx(css.text, textStyle)} />}
      </div>
    </div>
  )
}

EditorialTwoColumnsImageText.defaultProps = {}

export default EditorialTwoColumnsImageText
