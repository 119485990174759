import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import { ImageProps } from '~/components/Abstracts/Image'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import BlurredCta from '~/components/UI/BlurredCta'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialColoredBlockProps {
  className?: string
  title?: string
  subtitle?: string
  paragraph?: RichTextBlock
  cta?: LinkProps
  image?: ImageProps
  backgroundColor?: string
  theme?: GlobalThemeColors
}

const IMAGE_RATIO = 1
const IMAGE_SIZE = [{ breakpoint: 'md', ratio: 275 / 1440 }, { ratio: 3 / 12 }]

function EditorialColoredBlock({
  className,
  title,
  subtitle,
  paragraph,
  cta,
  image,
  theme,
  backgroundColor,
}: EditorialColoredBlockProps) {
  const isFirstSlice = useIsFirstSlice()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18Primary,
    color: theme,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Primary,
    textStyling: GlobalTextStyling.UpperCase,
    color: theme,
  })
  const paragraphStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Secondary,
    color: theme,
  })

  const hasContent = isRTFilled(paragraph) || title || subtitle || cta

  return (
    <div className={cx(css.EditorialColoredBlock, className, gridStyle)}>
      <div className={cx(css.container)} style={{ backgroundColor }}>
        {image && (
          <div className={cx(css.imageContainer)}>
            <Image
              className={cx(css.image)}
              sizesFromBreakpoints={IMAGE_SIZE}
              ratio={IMAGE_RATIO}
              layout="fill"
              objectFit="cover"
              draggable="false"
              priority={isFirstSlice}
              {...image}
            />
          </div>
        )}
        {hasContent && (
          <div className={cx(css.content)}>
            {title && <h5 className={cx(css.title, titleStyle)}>{title}</h5>}
            {subtitle && (
              <h5 className={cx(css.subtitle, subtitleStyle)}>{subtitle}</h5>
            )}
            <RichText
              className={cx(css.paragraph, paragraphStyle)}
              render={paragraph}
            />
            {cta && (
              <BlurredCta
                className={cx(css.cta)}
                theme={
                  theme === GlobalThemeColors?.Black
                    ? GlobalThemeColors.Black
                    : GlobalThemeColors.White
                }
                {...cta}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

EditorialColoredBlock.defaultProps = {}

export default EditorialColoredBlock
