import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import { ImageProps } from '~/components/Abstracts/Image'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialImagesColumnsProps {
  className?: string
  images?: ImageProps[]
}

const IMAGE_SIZES = {
  LAYOUT_1: [{ breakpoint: 'md', ratio: 6 / 12 }, { ratio: 1 }],
  LAYOUT_2: [{ breakpoint: 'md', ratio: 4 / 12 }, { ratio: 1 }],
}

const IMAGE_RATIO = 2 / 3

function EditorialImagesColumns({
  className,
  images,
}: EditorialImagesColumnsProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const isFirstSlice = useIsFirstSlice()
  const layout = images?.length > 2 ? 'small' : 'large'
  if (images?.length === 0) return null
  return (
    <div
      className={cx(css.EditorialImagesColumns, className, gridStyle, layout)}>
      {images?.map((image, i) => (
        <div key={i} className={cx(css.imageContainer)}>
          <Image
            className={cx(css.image)}
            draggable={false}
            sizesFromBreakpoints={
              layout === 'small' ? IMAGE_SIZES?.LAYOUT_1 : IMAGE_SIZES?.LAYOUT_2
            }
            ratio={IMAGE_RATIO}
            layout="fill"
            priority={isFirstSlice && i < 2}
            objectFit="cover"
            asPlaceholder
            {...image}
          />
        </div>
      ))}
    </div>
  )
}

EditorialImagesColumns.defaultProps = {}

export default EditorialImagesColumns
