import VideoControls, {
  VideoControlsProps,
  PlayPauseButton,
  MuteUnmuteButton,
  ProgressBar,
  PlayPauseButtonProps,
  MuteUnmuteButtonProps,
  ProgressBarProps,
} from './VideoControls'
import VideoPlayer, { VideoPlayerProps } from './VideoPlayer'
import VideoPlayerMock, { VideoPlayerMockProps } from './VideoPlayerMock'
import { useVideoState, useVideoTypeFromSrc, VideoState } from './hooks'

export {
  VideoPlayerMock,
  VideoControls,
  PlayPauseButton,
  MuteUnmuteButton,
  ProgressBar,
  useVideoState,
  useVideoTypeFromSrc,
}

export type {
  VideoPlayerProps,
  VideoControlsProps,
  PlayPauseButtonProps,
  MuteUnmuteButtonProps,
  ProgressBarProps,
  VideoPlayerMockProps,
  VideoState,
}

export default VideoPlayer
