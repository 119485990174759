import { Richtext, StoryblokComponent } from 'storyblok-js-client'
import { GlobalThemeColors, TThemeColors } from '~/@types/colors'
import { MultistoreKeys } from '~/@types/constants'
import {
  TSbImage,
  TSbLinkComponent,
  TSbRichText,
} from '~/@types/storyblok-js-extended'

import { RichTextBlock } from '~/components/Abstracts/RichText'
import { BlurredCtaProps } from '~/components/UI/BlurredCta'
import { ImageProps } from '~/components/UI/Image'

import serializeCta from '~/data/serialize-cta'
import serializeImage from '~/data/serialize-image'

export const HERO_BANNER_LAYOUT = {
  SIMPLE: 'simple',
  DUO: 'duo',
  HYBRID: 'hybrid',
} as const

export interface HeroBannerContent {
  image: ImageProps | null
  imageMobile: ImageProps | null
  title: RichTextBlock | null
  label: RichTextBlock | null
  caption: RichTextBlock | null
  cta: BlurredCtaProps | null
}

export interface HeroBannerImage {
  hideOnMobile: boolean
  backgroundColor?: string
  image: ImageProps | null
  imageMobile: ImageProps | null
}

export interface HeroBannerSimpleItem
  extends HeroBannerImage,
    HeroBannerContent {
  className?: string
}
export interface HeroBannerHybridItem extends HeroBannerContent {
  images: HeroBannerImage[]
}

export interface HeroBannerProps {
  items: Array<
    | { layout: 'simple'; content: HeroBannerSimpleItem }
    | { layout: 'duo'; content: HeroBannerSimpleItem[] }
    | { layout: 'hybrid'; content: HeroBannerHybridItem }
  >
  theme: TThemeColors
}

type TSbPickerPlugin = {
  color?: string
  plugin?: 'native-color-picker'
  _uid?: string
}

export type TSbHeroBannerItemData = Partial<
  {
    layout: 'simple' | 'duo' | 'hybrid'
    primary_background: TSbImage
    primary_background_mobile: TSbImage
    primary_background_color: TSbPickerPlugin
    primary_caption: TSbRichText
    primary_cta: [TSbLinkComponent]
    primary_hide_on_mobile: boolean
    primary_label: Richtext
    primary_title: Richtext
    secondary_background: TSbImage
    secondary_background_color: TSbPickerPlugin
    secondary_mobile_background: TSbImage
    secondary_caption: Richtext
    secondary_cta: [TSbLinkComponent]
    secondary_hide_on_mobile: boolean
    secondary_label: Richtext
    secondary_title: Richtext
    theme: TThemeColors
  } & StoryblokComponent<'hero_banner_item'>
>

export type TSbHeroBannerData = {
  body: [TSbHeroBannerItemData]
  // TODO : Remove if the Theme tab of HeroBanner is remove in Storyblok
  theme: []
} & StoryblokComponent<'hero_banner'>

export default function serializeHeroBanner(
  slice: TSbHeroBannerData,
  locale: MultistoreKeys,
): HeroBannerProps {
  const slicesData =
    slice?.body?.map?.((item) => {
      const primaryImages = {
        hideOnMobile: item?.primary_hide_on_mobile ?? false,
        backgroundColor: item?.primary_background_color?.color ?? '#fff',
        image: serializeImage(item?.primary_background) ?? null,
        imageMobile:
          serializeImage(item?.primary_background_mobile) ??
          serializeImage(item?.primary_background) ??
          null,
      } as HeroBannerImage

      const primaryContent = {
        title: item?.primary_title ?? null,
        label: item?.primary_label ?? null,
        caption: item?.primary_caption ?? null,
        cta: serializeCta(locale, item?.primary_cta) ?? null,
      } as Omit<HeroBannerContent, 'image' | 'imageMobile'>

      const secondaryImages = {
        hideOnMobile: item?.secondary_hide_on_mobile ?? false,
        backgroundColor: item?.secondary_background_color?.color ?? '#fff',
        image: serializeImage(item?.secondary_background) ?? null,
        imageMobile:
          serializeImage(item?.secondary_mobile_background) ??
          serializeImage(item?.secondary_background) ??
          null,
      } as HeroBannerImage

      const secondaryContent = {
        title: item?.secondary_title ?? null,
        label: item?.secondary_label ?? null,
        caption: item?.secondary_caption ?? null,
        cta: serializeCta(locale, item?.secondary_cta) ?? null,
      } as Omit<HeroBannerContent, 'image' | 'imageMobile'>

      switch (item?.layout) {
        case HERO_BANNER_LAYOUT.SIMPLE:
          return {
            layout: HERO_BANNER_LAYOUT.SIMPLE,
            content: {
              ...primaryImages,
              ...primaryContent,
            } as HeroBannerSimpleItem,
          }

        case HERO_BANNER_LAYOUT.DUO:
          return {
            layout: HERO_BANNER_LAYOUT.DUO,
            content: [
              { ...primaryImages, ...primaryContent },
              { ...secondaryImages, ...secondaryContent },
            ] as HeroBannerSimpleItem[],
          }

        case HERO_BANNER_LAYOUT.HYBRID:
          return {
            layout: HERO_BANNER_LAYOUT.HYBRID,
            content: {
              ...primaryContent,
              images: [primaryImages, secondaryImages],
            } as HeroBannerHybridItem,
          }
      }
    }) ?? []

  const theme = slice?.body?.[0]?.theme ?? GlobalThemeColors.Black

  return { items: slicesData, theme }
}
