import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { TRACKING_EVENTS } from '~/lib/constants'
import { STORYBLOK_COMPONENTS } from '~/lib/storyblok-types'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import {
  PushGridItemData,
  PushGridItemProps,
} from '~/components/Abstracts/PushGridItem'
import SliderControls from '~/components/Abstracts/SliderControls'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import SliderItemSticker from '~/components/Slices/HeroMain/SliderItemSticker'
import Image from '~/components/UI/Image'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackPromotionListCardList from '~/hooks/tracking/useTrackPromotionList'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 }, { ratio: 1 }]

export interface HeroMainProps {
  items: PushGridItemProps[]
}

function HeroMain({ items }: HeroMainProps) {
  const { theme } = useGlobalData()
  const t = useTranslate()
  const customSliderRef = useRef()
  const [withBanner] = useNavBannerContext()
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()
  const isFirstSlice = useIsFirstSlice()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const handleClick = (item: PushGridItemProps) => {
    if (onClickOnPromotion) {
      onClickOnPromotion({
        event: TRACKING_EVENTS.PROMOTION_CLICK,
        items: [item],
      })
    }
  }

  const isCategoryPushItem = (item: PushGridItemData) => {
    return (
      item?.type === STORYBLOK_COMPONENTS.PUSH_GRID_CATEGORY_ITEM ||
      item?.type === STORYBLOK_COMPONENTS.PUSH_GRID_ITEM
    )
  }

  const [setRef, onClickOnPromotion] = useTrackPromotionListCardList(
    items?.filter((item) => isCategoryPushItem(item)),
  )

  const imageProps = {
    sizesFromBreakpoints: IMAGE_SIZES,
    quality: 90,
  }

  const isSingleItem = items?.length === 1

  const router = useRouter()

  return (
    <div
      className={cx(css.HeroMain, {
        hasControls: items?.length > 1,
        withBanner,
      })}
      ref={setRef}>
      <div className={css.sliderWrapper} ref={customSliderRef}></div>

      <Slider
        className={cx(css.slider)}
        customSliderRef={customSliderRef}
        maxSlideIndexChange={1}
        infinite
        setSliderState={setSliderState}
        snap>
        {items?.map((item, index) => {
          const isFirst = index === 0

          return (
            <div
              className={cx(css.sliderItem, {
                isSingleItem,
              })}
              onClick={() => {
                if (item?.cta?.href) {
                  router.push(item.cta.href)
                  isCategoryPushItem(item) && handleClick(item)
                }
              }}
              key={`slider_item_${index}`}>
              <Image
                className={cx(
                  css.image,
                  item?.imageMobile ? css.hideOnSmallScreen : null,
                )}
                layout="fill"
                objectFit="cover"
                asPlaceholder={!(isFirst && isFirstSlice)}
                priority={isFirst && isFirstSlice}
                {...imageProps}
                {...item?.image}
              />
              {item?.imageMobile && (
                <Image
                  className={cx(css.image, css.hideOnLargeScreen)}
                  layout="fill"
                  objectFit="cover"
                  asPlaceholder={!(isFirst && isFirstSlice)}
                  priority={isFirst && isFirstSlice}
                  {...imageProps}
                  {...item?.imageMobile}
                />
              )}
            </div>
          )
        })}
      </Slider>

      <div className={cx(css.sliderStickers)}>
        <div className={gridStyle}>
          <div className={css.stickersWrapper}>
            {items?.map((item, index) => (
              <SliderItemSticker
                index={index}
                isFirstSlice={isFirstSlice}
                currentIndex={slideIndex}
                className={css.sticker}
                title={item?.title}
                cta={item?.cta}
                key={`slider_sticker_${index}`}
                {...(isCategoryPushItem(item) && {
                  onClick: () => handleClick(item),
                })}
              />
            ))}
          </div>
        </div>
      </div>
      {items?.length > 1 && (
        <div className={cx(css.sliderControls, css?.[`theme-${theme?.nav}`])}>
          <SliderControls
            length={items?.length}
            className={css.sliderControlsItem}
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
          />
        </div>
      )}
    </div>
  )
}

HeroMain.defaultProps = {}

export default HeroMain
