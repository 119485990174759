import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Link } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import Tag from '~/components/Abstracts/Tag'
import Image, { ImageProps } from '~/components/UI/Image'
import { InlineCtaProps } from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import { MAGAZINE_ITEM_LAYOUT } from '~/data/slices-data/push-slider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const fetchRatio = (layout) => {
  const ratios = {
    [MAGAZINE_ITEM_LAYOUT.DEFAULT]: 330 / 412,
    [MAGAZINE_ITEM_LAYOUT.OVAL]: 330 / 370,
    [MAGAZINE_ITEM_LAYOUT.ROUND]: 1 / 1,
    [MAGAZINE_ITEM_LAYOUT.SQUARE]: 330 / 412,
  }

  return ratios[layout] || 330 / 412
}

export interface MagazineItemProps {
  className?: string
  layout?: string
  image?: ImageProps
  tag?: string
  title?: RichTextBlock
  subtitle?: RichTextBlock
  cta?: InlineCtaProps
  imgProps?: Omit<ImageProps, 'src'>
}

function MagazineItem({
  className,
  layout,
  image,
  tag,
  title,
  subtitle,
  cta,
  imgProps,
}: MagazineItemProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  const spanStyle = useStyle({
    color: GlobalThemeColors.Gray2ToBlack,
    textPreset: GlobalTextPreset.Cta10Primary,
  })

  const Content = (
    <>
      <Image
        className={cx(css.image)}
        layout="fill"
        objectFit="cover"
        ratio={fetchRatio(layout)}
        {...image}
        {...imgProps}
      />
      {tag && (
        <Tag
          className={cx(css.tag)}
          label={tag}
          theme={GlobalThemeColors.White}
          textPreset={GlobalTextPreset.Label10Secondary}
          labelColor={GlobalThemeColors.Black}
          withBackground
        />
      )}
      {/* {(isRTFilled(title) || isRTFilled(subtitle)) && ( */}
      <div className={css.content}>
        {isRTFilled(title) && (
          <RichText className={cx(css.title, titleStyle)} render={title} />
        )}
        {isRTFilled(subtitle) && (
          <RichText
            className={cx(css.subtitle, subtitleStyle)}
            render={subtitle}
          />
        )}

        {cta && cta?.children && (
          <span className={cx(css.cta, spanStyle)}>{String(cta.children)}</span>
        )}
      </div>
    </>
  )

  return (
    <div className={cx(css.MagazineItem, css[`layout-${layout}`], className)}>
      {cta?.href ? <Link href={cta?.href}>{Content}</Link> : Content}
    </div>
  )
}

MagazineItem.defaultProps = {}

export default MagazineItem
