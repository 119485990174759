import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import Link from '~/components/Abstracts/Link'
import Ratio from '~/components/Abstracts/Ratio'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import BlurredCta, { BlurredCtaProps } from '~/components/UI/BlurredCta'
import Image, { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  fullSize: [{ breakpoint: 'md', ratio: 1 }, { ratio: 1 }],
  gridSize: [{ breakpoint: 'md', ratio: 566 / 1440 }, { ratio: 1 }],
}

export interface PushGridItemData {
  title?: string
  image?: ImageProps
  cta?: BlurredCtaProps
  type?: string
}

export interface PushGridItemProps extends PushGridItemData {
  className?: string
  isAnHero?: boolean
  isInGrid?: boolean
  isFirstItem?: boolean
  layout?: 'half' | 'full'
  imageMobile?: ImageProps
  onClick?: () => void
}

function PushGridItem({
  className,
  title,
  image,
  imageMobile,
  cta,
  isAnHero,
  isInGrid,
  isFirstItem,
  onClick,
  layout,
}: PushGridItemProps) {
  const titleStyle = useStyle({
    textPreset: isAnHero
      ? GlobalTextPreset.Title18_24Primary
      : GlobalTextPreset.Title18Primary,
  })

  const [withBanner] = useNavBannerContext()
  const isFirstSlice = useIsFirstSlice()

  const imageProps = {
    sizesFromBreakpoints: isInGrid
      ? IMAGE_SIZES.gridSize
      : IMAGE_SIZES.fullSize,
    asPlaceholder: !isFirstSlice,
    quality: 90,
  }

  return (
    <Link
      href={cta?.href}
      className={cx(css.PushGridItem, className, { isInGrid, withBanner })}
      onClick={() => onClick && onClick()}>
      <Ratio className={cx(css.ratio)} ratio={566 / 752}>
        {() => null}
      </Ratio>
      <div className={css.wrapper}>
        {image && (
          <Image
            className={cx(
              css.image,
              imageMobile ? css.hideOnSmallScreen : null,
            )}
            layout="fill"
            objectFit="cover"
            priority={isFirstSlice || isFirstItem}
            {...imageProps}
            {...image}
          />
        )}
        {imageMobile && (
          <Image
            className={cx(css.image, css.hideOnLargeScreen)}
            layout="fill"
            objectFit="cover"
            priority={isFirstSlice || isFirstItem}
            {...imageProps}
            {...imageMobile}
          />
        )}
        {(title || cta?.children) && (
          <div className={cx(css.container)}>
            <div className={cx(css.content)}>
              <h3 className={cx(titleStyle, css.title)}>{title}</h3>
              {cta?.children && (
                <BlurredCta
                  className={cx(css.cta)}
                  theme={GlobalThemeColors.Black}
                  isDiv>
                  {cta.children}
                </BlurredCta>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  )
}

PushGridItem.defaultProps = {
  isAnHero: false,
  isInGrid: false,
}

export default PushGridItem
