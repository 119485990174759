import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HeroEditorialProps {
  className?: string
  title?: string
  chapo?: RichTextBlock
}

function HeroEditorial({ className, title, chapo }: HeroEditorialProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.TitleFluid24_44Primary,
  })
  const chapoStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_18Primary,
  })

  return (
    <div className={cx(css.HeroEditorial, className, gridStyle)}>
      <h1 className={cx(css.title, titleStyle)}>{title}</h1>
      {chapo && (
        <RichText render={chapo} className={cx(css.chapo, chapoStyle)} />
      )}
    </div>
  )
}

HeroEditorial.defaultProps = {}

export default HeroEditorial
