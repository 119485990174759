import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import BlurredCta, { BlurredCtaProps } from '~/components/UI/BlurredCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SliderItemStickerProps {
  className?: string
  title?: string
  cta?: BlurredCtaProps
  index?: number
  currentIndex?: number
  isHover?: boolean
  isFirstSlice?: boolean
  onClick?: () => void
}

function SliderItemSticker({
  className,
  title,
  cta,
  currentIndex,
  index,
  isHover,
  isFirstSlice,
  onClick,
}: SliderItemStickerProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24Primary,
  })

  const isActive = index === currentIndex

  const variants = {
    show: { opacity: 1, y: 0, transition: { delay: 0.2 } },
    hide: { opacity: 0, y: 5 },
  }

  return (
    <m.div
      variants={variants}
      initial={false}
      animate={isActive ? 'show' : 'hide'}
      transition={{ duration: isActive ? 0.7 : 0.3, ease: 'easeOut' }}
      className={cx(css.SliderItemSticker, className, {
        isHover,
        isActive: currentIndex === index,
      })}>
      {isFirstSlice && index === 0 ? (
        <h1 className={cx(titleStyle, css.title)}>{title}</h1>
      ) : (
        <h3 className={cx(titleStyle, css.title)}>{title}</h3>
      )}
      {cta?.children && (
        <BlurredCta
          {...cta}
          className={cx(css.cta)}
          theme={GlobalThemeColors.Black}
          onClick={() => onClick && onClick()}>
          {cta.children}
        </BlurredCta>
      )}
    </m.div>
  )
}

SliderItemSticker.defaultProps = {
  isHover: false,
}

export default SliderItemSticker
