import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import BlurredCta from '~/components/UI/BlurredCta'
import { MailIcon, PhoneIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ColumnContactProps {
  className?: string
  title?: RichTextBlock
  description?: RichTextBlock
  cta?: LinkProps
  isPhone?: boolean
}

function ColumnContact({
  className,
  title,
  description,
  cta,
  isPhone,
}: ColumnContactProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_18Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  return (
    <div className={cx(css.ColumnContact, className)}>
      {title && (
        <RichText className={cx(css.title, titleStyle)} render={title} />
      )}
      {description && (
        <RichText
          className={cx(css.description, descriptionStyle)}
          render={description}
        />
      )}
      {cta && (
        <BlurredCta {...cta} className={css.cta}>
          <div className={css.label}>
            {isPhone && <PhoneIcon className={css.icon} />}
            {!isPhone && <MailIcon className={css.icon} />}
            <span>{cta.children}</span>
          </div>
        </BlurredCta>
      )}
    </div>
  )
}

ColumnContact.defaultProps = {}

export default ColumnContact
