import React, { useCallback, useState } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

function getRelativeCoordinates(pos, el) {
  const position = {
    x: pos.x,
    y: pos.y,
  }

  const offset = {
    left: el.offsetLeft,
    top: el.offsetTop,
  }

  let reference = el.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
  }
}

function formatEvent(e: React.TouchEvent | React.MouseEvent) {
  const el = e.currentTarget
  const ev =
    'touches' in e.nativeEvent ? e?.nativeEvent?.touches[0] : e?.nativeEvent

  const pos = {
    x: ev?.clientX,
    y: ev?.clientY,
  }

  return {
    el,
    pos,
  }
}

function useRelativePositions() {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  })

  const [canDrag, setCanDrag] = useState(false)

  function start(e: React.TouchEvent | React.MouseEvent) {
    setCanDrag(true)
    const { pos, el } = formatEvent(e)
    setPosition(getRelativeCoordinates(pos, el))
  }

  function move(e: React.TouchEvent | React.MouseEvent) {
    if (!canDrag) return
    const { pos, el } = formatEvent(e)
    setPosition(getRelativeCoordinates(pos, el))
  }

  const end = useCallback(() => setCanDrag(false), [])

  useIsomorphicLayoutEffect(() => {
    if (canDrag) {
      window.addEventListener('touchend', end)
      window.addEventListener('mouseup', end)
    } else {
      window.removeEventListener('touchend', end)
      window.removeEventListener('mouseup', end)
    }
  }, [canDrag])

  return {
    position,
    bindEvents: {
      onMouseDown: start,
      onMouseMove: move,
      onTouchStart: start,
      onTouchMove: move,
    },
  }
}

export default useRelativePositions
