import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import Link, { LinkProps } from '~/components/Abstracts/Link'
import Image, { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import Ratio from '../Abstracts/Ratio'
import Video, { VideoProps } from '../Abstracts/Video'
import css from './styles.module.scss'

const cx = classnames.bind(css)

interface CommonMedia {
  className: string
  isLarge?: boolean
}
interface Image extends CommonMedia {
  mediaType: 'image'
  mediaProps: ImageProps
}
interface Video extends CommonMedia {
  mediaType: 'video'
  mediaProps: VideoProps
}

type Media = Image | Video

export type MediaWithLink = Media & { link: LinkProps }
const IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 330 / 1440 },
  { ratio: 311 / 375 },
]
const LARGE_IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 684 / 1440 },
  { ratio: 311 / 375 },
]

export interface MediaGridProps {
  className?: string
  /**
   * Layout 1 is a grid of 4 small cards on the left side and a large card on the right side
   * Layout 2 is a grid of 4 small cards on the right side and a large card on the left side
   */
  media: MediaWithLink[]
  layout: 'layout-1' | 'layout-2'
}

export function Media({ className, mediaType, mediaProps, isLarge }: Media) {
  return mediaType === 'image' ? (
    <Image
      {...mediaProps}
      className={cx(className, css.media)}
      layout="fill"
      sizesFromBreakpoints={isLarge ? LARGE_IMAGE_SIZES : IMAGE_SIZES}
      objectFit="cover"
      loading="lazy"
      asPlaceholder
    />
  ) : (
    <Video
      {...mediaProps}
      className={cx(className, css.media, css.video)}
      objectFit="cover"
      loading="lazy"
      autoPlay
      muted
      loop
      playsInline
    />
  )
}

function MediaGrid({ className, media, layout }: MediaGridProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const largeImageIndex = layout === 'layout-1' ? 4 : 0
  return (
    <div
      className={cx(css.MediaGrid, className, gridStyle, {
        [layout]: layout,
      })}>
      {media?.map((media, index) => {
        const isLarge = index === largeImageIndex
        return (
          <Ratio
            ratio={330 / 495}
            key={`${index}`}
            className={cx(css.mediaContainer)}>
            {(className) =>
              media?.link ? (
                <Link {...media?.link}>
                  <Media isLarge={isLarge} className={className} {...media} />
                </Link>
              ) : (
                <Media isLarge={isLarge} className={className} {...media} />
              )
            }
          </Ratio>
        )
      })}
    </div>
  )
}

MediaGrid.defaultProps = {}

export default MediaGrid
