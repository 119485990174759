import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'

import Slider from '@unlikelystudio/react-slider'

import { useStyle } from '~/providers/StyleProvider'

import RichText, { RichTextBlock } from '../Abstracts/RichText'
import Tag from '../Abstracts/Tag'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BannerProps {
  className?: string
  tag?: string
  text?: RichTextBlock
  speed?: number
}

function Banner({ className, text, tag, speed = -0.5 }: BannerProps) {
  const textStyle = useStyle({ textPreset: GlobalTextPreset.Title10Primary })
  return (
    <Slider
      autoIsSlider={false}
      infinite={true}
      dragProps={{
        enable: false,
      }}
      autoPlay={{
        speed,
      }}
      className={cx(css.Banner, className)}>
      <div className={css.bannerItem}>
        {tag && <Tag label={tag} className={css.tag} />}
        {text && <RichText className={cx(css.text, textStyle)} render={text} />}
      </div>
      <div className={css.bannerItem}>
        {tag && <Tag label={tag} className={css.tag} />}
        {text && <RichText className={cx(css.text, textStyle)} render={text} />}
      </div>
    </Slider>
  )
}

Banner.defaultProps = {}

export default Banner
