import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

import Banner from '~/components/Banner'
import EditorialColoredBlock from '~/components/EditorialColoredBlock'
import MediaGrid from '~/components/MediaGrid'
import BlogGrid from '~/components/Slices/BlogGrid'
import EditorialAccordions from '~/components/Slices/EditorialAccordions'
import EditorialImagesColumns from '~/components/Slices/EditorialImagesColumns'
import EditorialPushContact from '~/components/Slices/EditorialPushContact'
import EditorialSliderImagesWithCaptions from '~/components/Slices/EditorialSliderImagesWithCaptions'
import EditorialText from '~/components/Slices/EditorialText'
import EditorialTwoColumnsContact from '~/components/Slices/EditorialTwoColumnsContact'
import EditorialTwoColumnsImageText from '~/components/Slices/EditorialTwoColumnsImageText'
import EditorialVideo from '~/components/Slices/EditorialVideo'
import HeroBanner from '~/components/Slices/HeroBanner'
import HeroEditorial from '~/components/Slices/HeroEditorial'
import HeroMain from '~/components/Slices/HeroMain'
import HorizontalMedia from '~/components/Slices/HorizontalMedia'
import ListWorkshops from '~/components/Slices/ListWorkshops'
import ProductTitleSlider from '~/components/Slices/ProductTitleSlider'
import PushGrid from '~/components/Slices/PushGrid'
import PushProducts from '~/components/Slices/PushProducts'
import PushSlider from '~/components/Slices/PushSlider'

export default {
  [STORYBLOK_SLICES.HERO_MAIN]: HeroMain,
  [STORYBLOK_SLICES.HERO_BANNER]: HeroBanner,
  [STORYBLOK_SLICES.MEDIA_GRID]: MediaGrid,
  [STORYBLOK_SLICES.BANNER]: Banner,
  [STORYBLOK_SLICES.EDITORIAL_TEXT]: EditorialText,
  [STORYBLOK_SLICES.EDITORIAL_TWO_COLUMNS_IMAGE_TEXT]:
    EditorialTwoColumnsImageText,
  [STORYBLOK_SLICES.EDITORIAL_IMAGES_COLUMNS]: EditorialImagesColumns,
  [STORYBLOK_SLICES.EDITORIAL_SLIDER_IMAGES_WITH_CAPTIONS]:
    EditorialSliderImagesWithCaptions,
  [STORYBLOK_SLICES.HERO_EDITORIAL]: HeroEditorial,
  [STORYBLOK_SLICES.EDITORIAL_VIDEO]: EditorialVideo,
  [STORYBLOK_SLICES.EDITORIAL_ACCORDIONS]: EditorialAccordions,
  [STORYBLOK_SLICES.PUSH_GRID]: PushGrid,
  [STORYBLOK_SLICES.EDITORIAL_COLORED_BLOCK]: EditorialColoredBlock,
  [STORYBLOK_SLICES.EDITORIAL_PUSH_CONTACT]: EditorialPushContact,
  [STORYBLOK_SLICES.EDITORIAL_TWO_COLUMNS_CONTACT]: EditorialTwoColumnsContact,
  [STORYBLOK_SLICES.PRODUCT_TITLE_SLIDER]: ProductTitleSlider,
  [STORYBLOK_SLICES.PUSH_PRODUCTS]: PushProducts,
  [STORYBLOK_SLICES.LIST_WORKSHOPS]: ListWorkshops,
  [STORYBLOK_SLICES.HORIZONTAL_MEDIA]: HorizontalMedia,
  [STORYBLOK_SLICES.BLOG_GRID]: BlogGrid,
  [STORYBLOK_SLICES.PUSH_SLIDER]: PushSlider,
}
