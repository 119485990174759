import classnames from 'classnames/bind'
import { Fragment, useRef } from 'react'
import { TThemeColors } from '~/@types/colors'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import RichText from '~/components/Abstracts/RichText'
import SliderControls from '~/components/Abstracts/SliderControls'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import BlurredCta, { BlurredCtaColors } from '~/components/UI/BlurredCta'
import Image, { ImageProps } from '~/components/UI/Image'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import { isRTFilled } from '~/utils/check-empty-string'

import {
  HERO_BANNER_LAYOUT,
  HeroBannerHybridItem,
  HeroBannerProps,
  HeroBannerSimpleItem,
} from '~/data/slices-data/hero-banner'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SlideOpts {
  theme?: TThemeColors
  slideIndex?: number
  isFirst?: boolean
}

const IMAGE_SIZES = {
  SOLO: [{ ratio: 1 }],
  DUO: [{ ratio: 1 / 2, breakpoint: 'md' }, { ratio: 1 }],
}

export interface SimpleSlideProps extends HeroBannerSimpleItem, SlideOpts {
  sizesFromBreakpoints: ImageProps['sizesFromBreakpoints']
  fullHeight?: boolean
}

export interface HybridSlideProps extends HeroBannerHybridItem, SlideOpts {
  backgroundColor?: string
}

function renderImage(item, isFirst, imgProps = {}, index = 0, slideIndex = 0) {
  const imageProps = {
    quality: 90,
    ...imgProps,
  }

  const hasPriority = isFirst && !item?.hideOnMobile

  const image = (
    <Image
      className={cx(
        css.image,
        item?.imageMobile ? css.hideOnSmallScreen : null,
      )}
      key={`desktop_image_${slideIndex}_${index}`}
      layout="fill"
      objectFit="cover"
      asPlaceholder
      priority={hasPriority}
      asPlaceHolder={!hasPriority}
      {...item?.image}
      {...imageProps}
    />
  )

  const mobileImage = item?.imageMobile ? (
    <Image
      className={cx(css.image, css.hideOnLargeScreen)}
      key={`mobile_image_${slideIndex}_${index}`}
      layout="fill"
      objectFit="cover"
      priority={hasPriority}
      asPlaceHolder={!hasPriority}
      {...item?.imageMobile}
      {...imageProps}
    />
  ) : null

  return (
    <Fragment key={`image_container_${slideIndex}_${index}`}>
      {image}
      {!item?.hideOnMobile && mobileImage}
    </Fragment>
  )
}

function renderEdito(label, title, cta, theme, slideIndex) {
  const labelStyle = useStyle({
    textPreset: 'label-bch-12-10-secondary',
    textStyling: GlobalTextStyling.UpperCase,
    color: theme,
  })

  const titleStyle = useStyle({
    textPreset: 'title-bch-48-96-primary',
    color: theme,
  })

  const ctaStyle = useStyle({
    textPreset: 'cta-14-primary-reg',
    color: theme,
  })

  return (
    <div className={cx(css.editoMiddle)} key={`edito_${slideIndex}`}>
      {isRTFilled(label) && (
        <RichText className={cx(css.label, labelStyle)} render={label} />
      )}

      {isRTFilled(title) && (
        <RichText className={cx(css.title, titleStyle)} render={title} />
      )}

      {cta?.children && (
        <BlurredCta
          className={cx(css.cta, ctaStyle)}
          isBig
          theme={theme as BlurredCtaColors}
          {...cta}>
          {cta.children}
        </BlurredCta>
      )}
    </div>
  )
}

function renderEditoBottom(caption, theme, slideIndex) {
  const captionStyle = useStyle({
    textPreset: 'text-12-secondary',
    color: theme,
  })

  return (
    <div className={cx(css.editoMiddle)} key={`edito_bottom_${slideIndex}`}>
      <RichText
        className={cx(css.editoBottom, captionStyle, css.hideOnSmallScreen)}
        render={caption}
      />
    </div>
  )
}

function Slide({ theme, layout, isSlider, content, index }) {
  const isFirstSlice = useIsFirstSlice()

  const opts = { isFirst: isFirstSlice && index === 0, theme }

  const slideContent =
    layout === HERO_BANNER_LAYOUT.SIMPLE ? (
      <SimpleSlide
        {...opts}
        {...content}
        slideIndex={index}
        key={`simple_slide_${index}`}
      />
    ) : layout === HERO_BANNER_LAYOUT.DUO ? (
      <DuoSlide
        isSlider={isSlider}
        {...opts}
        {...content}
        slideIndex={index}
        key={`duo_slide_${index}`}
      />
    ) : layout === HERO_BANNER_LAYOUT.HYBRID ? (
      <HybridSlide
        {...opts}
        {...content}
        slideIndex={index}
        key={`hybrid_slide_${index}`}
      />
    ) : null

  return slideContent
}

function SimpleSlide({
  className,
  theme,
  isFirst,
  backgroundColor,
  caption,
  sizesFromBreakpoints,
  label,
  title,
  cta,
  slideIndex,
  fullHeight,
  ...rest
}: SimpleSlideProps) {
  return (
    <div
      style={{ backgroundColor }}
      className={cx(css.simpleSlide, className, { fullHeight })}>
      {renderImage(rest, isFirst, {
        sizesFromBreakpoints: sizesFromBreakpoints ?? IMAGE_SIZES.SOLO,
      })}

      {renderEdito(label, title, cta, theme, slideIndex)}

      {renderEditoBottom(caption, theme, slideIndex)}
    </div>
  )
}

function DuoSlide({
  theme,

  isFirst,
  isSlider,
  backgroundColor,
  slideIndex,
  ...item
}: any) {
  const slides: HeroBannerSimpleItem[] = Object.values(item)
  const slidesCpt = slides.filter((slide) => !slide?.hideOnMobile).length
  const asBanner = !isSlider

  const mobileItems = isSlider
    ? slides
        .filter((slide) => !slide?.hideOnMobile)
        .map((slide, index) => (
          <SimpleSlide
            key={`${slideIndex}_${index}`}
            isFirst={isFirst}
            theme={theme}
            sizesFromBreakpoints={IMAGE_SIZES.DUO}
            {...slide}
          />
        ))
    : slides
        .filter((slide) => !slide?.hideOnMobile)
        .map((slide, index) => (
          <SimpleSlide
            key={`${slideIndex}_${index}`}
            isFirst={isFirst}
            theme={theme}
            sizesFromBreakpoints={IMAGE_SIZES.DUO}
            fullHeight={slidesCpt <= 1 && asBanner}
            {...slide}
          />
        ))

  const desktopItems = slides.map((slide, index) => (
    <SimpleSlide
      key={`${slideIndex}_desktop_${index}`}
      isFirst={isFirst}
      theme={theme}
      sizesFromBreakpoints={IMAGE_SIZES.DUO}
      {...slide}
    />
  ))

  return (
    <>
      <div
        style={{ backgroundColor }}
        key={`duo_slide_${slideIndex}`}
        className={cx(css.duoSlide, css.mobile, {
          asBanner: !isSlider,
        })}>
        {mobileItems}
      </div>
      <div
        style={{ backgroundColor }}
        key={`duo_slide_${slideIndex}`}
        className={cx(css.duoSlide, css.desktop)}>
        {desktopItems}
      </div>
    </>
  )
}

function HybridSlide({
  theme,
  isFirst,
  backgroundColor,
  caption,
  images,
  label,
  title,
  cta,
  slideIndex,
}: HybridSlideProps) {
  const imageLayout =
    images
      ?.map((image, imageIndex) =>
        renderImage(
          image,
          isFirst && imageIndex === 0,
          { sizesFromBreakpoints: IMAGE_SIZES.DUO },
          imageIndex,
          slideIndex,
        ),
      )
      ?.filter((image) => image) ?? null

  return (
    <div
      style={{ backgroundColor }}
      className={cx(css.hybridSlide)}
      key={`hybrid_slide_${slideIndex}`}>
      {imageLayout && (
        <div className={css.hybridImagesContainer}>{imageLayout}</div>
      )}

      {renderEdito(label, title, cta, theme, slideIndex)}

      {renderEditoBottom(caption, theme, slideIndex)}
    </div>
  )
}

function HeroBanner({ items, theme: heroBannerTheme }: HeroBannerProps) {
  const { theme } = useGlobalData()

  const customSliderRef = useRef()
  const [withBanner] = useNavBannerContext()
  const [{ slideIndex, setSlideIndex, enableDrag }, setSliderState] =
    useSliderState()
  const isSlider = enableDrag

  const sliderControls = items?.length > 1 && (
    <div className={cx(css.sliderControls, css?.[`theme-${theme?.nav}`])}>
      <SliderControls
        length={items?.length}
        className={css.sliderControlsItem}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
      />
    </div>
  )

  return (
    <div className={cx(css.HeroBanner, { withBanner })}>
      <Slider
        className={cx(css.slider)}
        customSliderRef={customSliderRef}
        setSliderState={setSliderState}
        infinite
        snap>
        {items?.map((item, index) => (
          <Slide
            index={index}
            key={`slide_index_${index}`}
            theme={heroBannerTheme}
            isSlider={isSlider}
            {...item}
          />
        ))}
      </Slider>
      {sliderControls}
    </div>
  )
}

export default HeroBanner
