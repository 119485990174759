import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import { asText } from '~/components/Abstracts/Storyblok'
import BlurredCta from '~/components/UI/BlurredCta'
import { LinkIcon, TickedIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'

import useCopyToClipboard from '~/hooks/useCopyToClipboard'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialPushContactProps {
  className?: string
  title?: RichTextBlock
  description?: RichTextBlock
  cta?: LinkProps
  isAddress?: boolean
}

function EditorialPushContact({
  className,
  title,
  description,
  cta,
  isAddress,
}: EditorialPushContactProps) {
  const t = useTranslate()
  const { isCopied, copyToClipboard } = useCopyToClipboard()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title10Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  const onClickCopy = () => {
    copyToClipboard(asText(description))
  }

  return (
    <div className={cx(css.EditorialPushContact, gridStyle, className)}>
      <div className={css.box}>
        <div className={css.left}>
          {title && (
            <RichText className={cx(css.title, titleStyle)} render={title} />
          )}
          {description && (
            <RichText
              className={cx(css.description, descriptionStyle)}
              render={description}
            />
          )}
        </div>
        <div className={css.right}>
          {cta && !isAddress && <BlurredCta className={css.cta} {...cta} />}
          {isAddress && (
            <BlurredCta
              className={css.ctaCopy}
              disabled={isCopied}
              onClick={() => {
                if (!isCopied) {
                  onClickCopy()
                }
              }}>
              <div className={css.label}>
                {isCopied ? t(CTA.COPIED) : t(CTA.COPY_ADDRESS)}
                {isCopied ? (
                  <TickedIcon className={css.icon} />
                ) : (
                  <LinkIcon className={css.icon} />
                )}
              </div>
            </BlurredCta>
          )}
        </div>
      </div>
    </div>
  )
}

EditorialPushContact.defaultProps = {}

export default EditorialPushContact
