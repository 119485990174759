type VideoType = 'vimeo' | 'youtube' | null

export const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//
export const MATCH_URL_VIMEO = /vimeo\.com\/.+/
export const VIDEO_EXTENSIONS =
  /\.(mp4|og[gv]|webm|mov|m4v)(#t=[,\d+]+)?($|\?)/i
export const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i

export const canPlay = {
  youtube: (url) => MATCH_URL_YOUTUBE.test(url),
  vimeo: (url) =>
    MATCH_URL_VIMEO.test(url) &&
    !VIDEO_EXTENSIONS.test(url) &&
    !HLS_EXTENSIONS.test(url),
}

export default function useVideoTypeFromSrc(src: string): VideoType {
  if (canPlay.youtube(src)) {
    return 'youtube'
  }

  if (canPlay.vimeo(src)) {
    return 'vimeo'
  }

  return null
}
