import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import React, {
  Dispatch,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
} from '@unlikelystudio/react-hooks'

import Ratio from '~/components/Abstracts/Ratio'
import { VideoProps } from '~/components/Abstracts/Video'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'
import useVideoTypeFromSrc from '~/components/Abstracts/VideoPlayer/hooks/useVideoTypeFromSrc'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const VideoPlayerVimeo = dynamic(
  () => import('~/components/Abstracts/VideoPlayer/VideoPlayerVimeo'),
  {
    ssr: false,
  },
)
const VideoPlayerYoutube = dynamic(
  () => import('~/components/Abstracts/VideoPlayer/VideoPlayerYoutube'),
  {
    ssr: false,
  },
)

export interface VideoPlayerProps extends Omit<VideoProps, 'onEnded'> {
  className?: string
  videoClassName?: string
  src?: string
  hasInView?: boolean
  hasPlaysInline?: boolean
  hasEventTracking?: boolean
  isConstrained?: boolean
  isAutoplay?: boolean
  isMuted?: boolean
  theme?: string
  loop?: boolean
  videoName?: string
  onDOMReady?: () => void
  onReady?: () => void
  onEnded?: () => void
  onPause?: () => void
  onPlay?: () => void
  onStart?: () => void
  onFullScreenChange?: () => void
  onExitView?: () => void
  onClick?: (e) => void
  children?: ReactNode | ReactNode[]
  ratio?: number
  setVideoState?: Dispatch<React.SetStateAction<VideoState>>
}

const VideoPlayer = ({
  className,
  videoClassName,
  src,
  children,
  isAutoplay,
  onDOMReady,
  onReady,
  onEnded,
  onPlay,
  onPause,
  onStart,
  onExitView,
  onClick,
  controls,
  hasPlaysInline,
  isConstrained,
  hasInView,
  isMuted,
  theme,
  loop,
  ratio,
  videoName,
  hasEventTracking,
  setVideoState,
}: VideoPlayerProps) => {
  const playerComponentRef = useRef<HTMLDivElement>()
  const videoRef = useRef(null)

  const { ref, inView: videoInView } = useInView({
    threshold: 0.2,
  })

  const setRef = useCallback(
    (node) => {
      ref(node)
      playerComponentRef.current = node
    },
    [ref],
  )

  const [videoIsHover, { onMouseEnter, onMouseLeave }] = useIsHover()
  const [videoStarted, setVideoStarted] = useState(true)
  const [videoMuted, setVideoMuted] = useState(isMuted)
  const [videoPaused, setVideoPaused] = useState(false)
  const [videoControls] = useState(controls)
  const [videoProgress, setVideoProgress] = useState(0)
  const [videoDuration, setVideoDuration] = useState(0)
  const [videoIsDOMReady, setVideoIsDOMReady] = useState(false)
  const [videoIsReady, setVideoIsReady] = useState(false)
  const [videoIsLoading, setVideoIsLoading] = useState(false)

  const VideoPlayerType = useVideoTypeFromSrc(src)
  const VideoInner = VideoPlayerType
    ? VideoPlayerType === 'vimeo'
      ? VideoPlayerVimeo
      : VideoPlayerYoutube
    : false

  useIsomorphicLayoutEffect(() => {
    if (!videoInView && hasInView) {
      setVideoPaused(true)
      onExitView && onExitView()
    }
  }, [videoInView])

  const onDurationChange = (duration) => {
    duration && setVideoDuration(duration)
  }

  const videoHandlePausePlay = () => {
    if (!videoStarted) setVideoStarted(true)
    setVideoPaused((prevState) => !prevState)
  }

  const videoHandleMuteUnmute = () => {
    setVideoMuted((prevState) => !prevState)
  }

  const videoHandleSeekTo = (seconds: number) => {
    videoRef?.current?.seekTo(seconds, 'seconds')

    // if (hasEventTracking) {
    //   trackVideoSeek(tracker, { videoName, seconds })
    // }
  }

  const updateProgress = (timing) => {
    const videoTime = timing?.playedSeconds
    const progress = videoTime / videoDuration

    if (!isNaN(progress) && hasEventTracking && !videoPaused) {
      // trackVideoProgress(tracker, { progress, videoName })
    }

    setVideoProgress(progress)
  }

  const onEndedCallback = useCallback(() => {
    setVideoPaused(true)

    if (!isNaN(videoProgress) && hasEventTracking && !videoPaused) {
      // trackVideoProgressEnd(tracker, { videoName })
    }

    videoHandleSeekTo(0)
    onEnded?.()
  }, [onEnded, hasEventTracking, videoProgress, videoPaused, videoName])

  const onStartCallback = useCallback(() => {
    setVideoStarted(true)
    setVideoPaused(false)
    onStart?.()
  }, [onStart])

  const onReadyCallback = useCallback(() => {
    setVideoIsDOMReady(true)
    onDOMReady?.()
  }, [onDOMReady])

  const onPauseCallback = useCallback(() => {
    setVideoPaused(true)

    if (hasEventTracking) {
      // trackVideoPause(tracker, { videoName })
    }

    onPause?.()
  }, [onPause, hasEventTracking, videoName])

  const onPlayCallback = useCallback(() => {
    setVideoPaused(false)

    if (hasEventTracking) {
      // trackVideoPlay(tracker, { videoName })
    }

    onPlay?.()
  }, [onPlay, hasEventTracking, videoName])

  const onBuffer = () => {
    setVideoIsLoading(true)
  }

  const onBufferEnd = () => {
    setVideoIsLoading(false)
    if (!videoIsReady) {
      setVideoIsReady(true)
      onReady?.()
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (!videoIsDOMReady) {
      return
    }

    setVideoIsLoading(false)

    if (!isAutoplay) {
      setVideoPaused(true)
    } else {
      setVideoPaused(false)
    }
  }, [isAutoplay, videoIsDOMReady])

  useIsomorphicLayoutEffect(() => {
    setVideoState?.({
      videoIsLoading,
      videoStarted,
      videoMuted,
      videoPaused,
      videoControls,
      videoInView,
      videoProgress,
      videoDuration,
      videoIsHover,
      videoIsReady,
      videoIsDOMReady,
      videoHandlePausePlay,
      videoHandleMuteUnmute,
      videoHandleSeekTo,
    })
  }, [
    videoIsLoading,
    videoProgress,
    videoStarted,
    videoMuted,
    videoPaused,
    videoControls,
    videoInView,
    videoDuration,
    videoIsHover,
    videoIsReady,
    videoIsDOMReady,
  ])

  const props = {
    hasPlaysInline,
    videoPaused,
    videoClassName,
    videoRef,
    onStart: onStartCallback,
    onDurationChange,
    onEnded: onEndedCallback,
    onPause: onPauseCallback,
    onPlay: onPlayCallback,
    updateProgress,
    onReady: onReadyCallback,
    onClick: onClick,
    src,
    loop,
    onBuffer,
    onBufferEnd,
    controls: videoControls,
    isMuted: videoMuted,
  }

  return (
    <div
      ref={setRef}
      className={cx(css.VideoPlayer, css[`${theme}Theme`], className)}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}>
      {VideoInner && (
        <>
          {isConstrained ? (
            <Ratio ratio={ratio ?? 16 / 9} className={css.wrapper}>
              {(cn) => (
                <VideoInner className={cn} {...props}>
                  {children}
                </VideoInner>
              )}
            </Ratio>
          ) : (
            <VideoInner className={css.videoInner} {...props}>
              {children}
            </VideoInner>
          )}
        </>
      )}
    </div>
  )
}

VideoPlayer.defaultProps = {
  controls: true,
  hasInView: true,
  hasPlaysInline: false,
  hasEventTracking: false,
  isConstrained: true,
  isMuted: false,
  loop: false,
}

export default VideoPlayer
