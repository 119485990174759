import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { Card } from '~/@types/card'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { UnlikelyVariant } from '@unlikelystudio/commerce-connector'

import Link from '~/components/Abstracts/Link'
import Image, { Sizes } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import { PRODUCT } from '~/data/dictionary'
import serializePrice from '~/data/serialize-price'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type HorizontalMediaItemData = Partial<
  Pick<UnlikelyVariant, 'price' | 'compareAtPrice'>
> & {
  name?: Card['name']
}

export interface HorizontalMediaItemProps
  extends Card,
    HorizontalMediaItemData {
  className?: string
  hasMultipleVariants?: boolean
}

const IMAGES_SIZES = [
  {
    ratio: 1,
  },
  {
    breakpoint: 'md',
    ratio: 1 / 12,
  },
] as Sizes

function HorizontalMediaItem({
  className,
  link,
  name,
  price,
  image,
  hasMultipleVariants,
  compareAtPrice,
}: HorizontalMediaItemProps) {
  const t = useTranslate()
  const locale = useLocale()
  const titleStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: GlobalTextPreset.Label12Primary,
  })

  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  const hasContent = name || price

  return (
    <div className={cx(css.HorizontalMediaItem, className)}>
      <Link {...link} className={css.cardLink}>
        <div className={css.cardContainer}>
          <div className={cx(css.imageWrapper)}>
            {image && (
              <Image
                className={cx(css.image)}
                draggable={false}
                sizesFromBreakpoints={IMAGES_SIZES}
                ratio={94 / 125}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                {...image}
              />
            )}
          </div>

          {hasContent && (
            <div className={cx(css.contentWrapper)}>
              {name && <h3 className={cx(titleStyle)}>{name}</h3>}

              <div className={cx(priceStyle)}>
                {hasMultipleVariants && <>{t(PRODUCT.PRICE_FROM)}&nbsp;</>}
                {compareAtPrice && (
                  <del
                    className={cx(
                      css.compareAtPrice,
                      GlobalTextPreset.Label12Secondary,
                    )}>
                    {serializePrice(
                      locale,
                      compareAtPrice.currencyCode,
                      compareAtPrice.amount,
                    )}
                  </del>
                )}
                <span className={priceStyle}>
                  {serializePrice(locale, price.currencyCode, price.amount)}
                </span>
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default HorizontalMediaItem
