import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useTracker } from '~/providers/TrackerProvider'

import { PromotionTrackingData } from '~/hooks/tracking'

export interface TriggerPromotionEventParams {
  event: string
  items?: PromotionTrackingData[]
}

export default function useTrackPromotionListCardList(
  promotionItems: PromotionTrackingData[],
) {
  const [setRef, inView] = useInView({ triggerOnce: true })
  const tracker = useTracker()

  useEffect(() => {
    if (inView) {
      triggerEvent({
        event: TRACKING_EVENTS.PROMOTION_VIEW,
        items: promotionItems,
      })
    }
  }, [inView])

  const triggerEvent = ({ event, items }: TriggerPromotionEventParams) => {
    tracker.emit<typeof event>(
      event,
      items?.map((item, index) => ({
        promotion_name: item?.title,
        location_id: item?.index ?? index,
      })),
    )
  }

  return [setRef, triggerEvent] as const
}
