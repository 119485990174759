import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalGridPreset } from '~/@types/grid-preset'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import {
  useVideoState,
  VideoControls,
  VideoControlsProps,
  VideoPlayerMock,
  VideoPlayerProps,
} from '~/components/Abstracts/VideoPlayer'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import { PauseIcon, PlayIcon } from '~/components/UI/Icons'
import { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const VideoPlayer = dynamic(
  () => import('~/components/Abstracts/VideoPlayer'),
  { ssr: false },
)

const cx = classnames.bind(css)

const VIDEO_RATIO = 16 / 9

export interface EditorialVideoProps extends VideoPlayerProps {
  className?: string
  imagePoster?: ImageProps
  controlsTheme?: VideoControlsProps['theme']
  videoPlayerClassName?: string
  isFullPage?: boolean
}

function EditorialVideo({
  className,
  imagePoster,
  controlsTheme,
  videoPlayerClassName,
  ratio,
  isFullPage,
  ...rest
}: EditorialVideoProps) {
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const isFirstSlice = useIsFirstSlice()
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  const containerRef = useRef()

  useEffect(() => {
    ref(containerRef.current)
  }, [ref])

  const [withBanner] = useNavBannerContext()

  const [
    {
      videoIsLoading,
      videoIsReady,
      videoMuted,
      videoPaused,
      videoProgress,
      videoDuration,
      videoIsHover,
      videoHandlePausePlay,
      videoHandleMuteUnmute,
      videoHandleSeekTo,
    },
    setVideoState,
  ] = useVideoState()

  const hasLoading = videoIsLoading && !videoIsReady
  const hide = videoIsReady && !videoPaused

  return (
    <div
      ref={containerRef}
      className={cx(
        css.EditorialVideo,
        className,
        isFullPage ? null : gridStyle,
        { isFullPage, withBanner },
      )}>
      {inView && (
        <VideoPlayer
          className={cx(css.videoPlayer, videoPlayerClassName)}
          hasPlaysInline={true}
          controls={false}
          setVideoState={setVideoState}
          ratio={ratio ?? VIDEO_RATIO}
          {...rest}>
          <>
            <VideoControls
              className={cx(css.videoControls)}
              videoIsLoading={videoIsLoading}
              videoMuted={videoMuted}
              videoPaused={videoPaused}
              videoProgress={videoProgress}
              videoDuration={videoDuration}
              videoIsHover={isMobile ? isMobile : videoIsHover}
              videoHandlePausePlay={videoHandlePausePlay}
              videoHandleMuteUnmute={videoHandleMuteUnmute}
              videoHandleSeekTo={videoHandleSeekTo}
              theme={controlsTheme}
              playPauseButtonClassName={css.playPauseButton}
              muteUnmuteButtonClassName={css.muteUnmuteButton}
              progressBarClassName={css.progressBar}
            />
            {!videoPaused && (
              <div
                className={css.playPauseLayer}
                onClick={videoHandlePausePlay}>
                <div className={css.pauseButton}>
                  <PauseIcon className={cx(css.playRoundedIcon)} />
                </div>
              </div>
            )}
          </>
        </VideoPlayer>
      )}

      <VideoPlayerMock
        className={cx(css.videoPlayerMock)}
        image={{ ...imagePoster, priority: isFirstSlice }}
        hide={hide}
        videoHandlePausePlay={videoHandlePausePlay}
        ratio={ratio ?? VIDEO_RATIO}
        icon={
          hasLoading ? (
            <LoadingComponent color="#fff" />
          ) : (
            <div className={css.playButton}>
              <PlayIcon className={cx(css.playRoundedIcon)} />
            </div>
          )
        }
      />
    </div>
  )
}

EditorialVideo.defaultProps = {
  isFullPage: false,
}

export default EditorialVideo
