import classnames from 'classnames/bind'
import { useRef } from 'react'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import SliderControls from '~/components/Abstracts/SliderControls'
import { CardProductProps } from '~/components/UI/CardProduct'
import HorizontalMediaItem from '~/components/UI/HorizontalMediaItem'
import Image, { ImageProps } from '~/components/UI/Image'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HorizontalMediaProps {
  className?: string
  backgroundColor?: string
  image?: ImageProps
  imageMobile?: ImageProps
  items?: CardProductProps[]
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 }, { ratio: 1 }]

function HorizontalMedia({
  className,
  backgroundColor,
  image,
  imageMobile,
  items,
}: HorizontalMediaProps) {
  const isFirstSlice = useIsFirstSlice()
  const imageProps = {
    sizesFromBreakpoints: IMAGE_SIZES,
    quality: 90,
    prirority: isFirstSlice,
  }

  const customSliderRef = useRef()
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()

  return (
    <div
      style={{ backgroundColor }}
      className={cx(css.HorizontalMedia, className)}>
      <Image
        className={cx(css.image, css.hideOnSmallScreen)}
        layout="fill"
        objectFit="cover"
        ratio={1440 / 620}
        {...imageProps}
        {...image}
      />

      {imageMobile && (
        <Image
          className={cx(css.image, css.hideOnLargeScreen)}
          layout="fill"
          objectFit="cover"
          ratio={1 / 1}
          {...imageProps}
          {...imageMobile}
        />
      )}

      <div className={cx(css.PushSliderDesktop, className)}>
        <>
          <div className={css.sliderContainer} ref={customSliderRef}>
            <Slider
              className={cx(css.slider)}
              customSliderRef={customSliderRef}
              maxSlideIndexChange={1}
              setSliderState={setSliderState}
              autoIsSlider={false}
              snap
              infinite>
              {items.map((item, index) => (
                <HorizontalMediaItem
                  key={index}
                  className={css.sliderItem}
                  {...item}
                />
              ))}
            </Slider>
          </div>
          <div className={css.sliderControls}>
            <SliderControls
              length={items?.length + 1}
              className={css.mobileSliderControls}
              slideIndex={slideIndex}
              setSlideIndex={setSlideIndex}
            />
          </div>
        </>
      </div>
    </div>
  )
}

export default HorizontalMedia
