import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import Slider from '@unlikelystudio/react-slider'

import { LinkProps } from '~/components/Abstracts/Link'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import BlurredCta from '~/components/UI/BlurredCta'
import { CardProductProps } from '~/components/UI/CardProduct'
import CardWorkshop from '~/components/UI/CardWorkshop'

import { useStyle } from '~/providers/StyleProvider'

import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ListWorkshopsProps {
  className?: string
  title?: RichTextBlock
  items?: CardProductProps[]
  cta?: LinkProps
}

function ListWorkshops({ className, title, items, cta }: ListWorkshopsProps) {
  const customSliderRef = useRef()
  const isFirstSlice = useIsFirstSlice()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18Primary,
  })

  return (
    <div
      className={cx(css.ListWorkshops, className, {
        hasNoCta: !(cta && cta?.children),
      })}>
      <div className={cx(gridStyle, css.top)}>
        {title && isRTFilled(title) && (
          <RichText render={title} className={cx(css.title, titleStyle)} />
        )}

        {cta && cta?.children && (
          <div className={cx(css.ctaWrapper, css.hideOnSmallScreen)}>
            <BlurredCta
              className={cx(css.cta)}
              theme={GlobalThemeColors.Shade1}
              {...cta}
            />
          </div>
        )}
      </div>

      {items && items.length > 0 && (
        <div className={cx(gridStyle, css.body)}>
          <div className={cx(css.itemsWrapper, css.hideOnLargeScreen)}>
            {items?.map((item, index) => (
              <CardWorkshop
                {...item}
                priority={isFirstSlice && index === 0}
                className={css.item}
                key={`card_workshop_${index}`}
              />
            ))}
          </div>

          <div
            className={cx(
              css.itemsWrapper,
              css.sliderContainer,
              css.hideOnSmallScreen,
            )}>
            <div className={css.slides} ref={customSliderRef}>
              <Slider
                className={cx(css.slider)}
                customSliderRef={customSliderRef}
                snap
                infinite>
                {items?.map((item, index) => (
                  <CardWorkshop
                    {...item}
                    className={css.item}
                    priority={isFirstSlice && index < 3}
                    key={`card_workshop_${index}`}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}

      {cta && cta?.children && (
        <div className={cx(gridStyle, css.bottom, css.hideOnLargeScreen)}>
          <div className={cx(css.ctaWrapper)}>
            <BlurredCta
              className={cx(css.cta)}
              theme={GlobalThemeColors.Shade1}
              {...cta}
            />
          </div>
        </div>
      )}
    </div>
  )
}

ListWorkshops.defaultProps = {}

export default ListWorkshops
