import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { TRACKING_EVENTS } from '~/lib/constants'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import SliderControls from '~/components/Abstracts/SliderControls'
import { convertNodeToText } from '~/components/Abstracts/Storyblok/as-text'
import SliderArrows from '~/components/SliderArrows'
import BlurredCta, { BlurredCtaProps } from '~/components/UI/BlurredCta'
import CardProduct, { CardProductProps } from '~/components/UI/CardProduct'

import { useStyle } from '~/providers/StyleProvider'

import useTrackProductCardsList from '~/hooks/tracking/useTrackProductCardsList'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PushProductsProps {
  className?: string
  title?: RichTextBlock
  items?: CardProductProps[]
  cta?: BlurredCtaProps
}

function PushProducts({ className, title, items, cta }: PushProductsProps) {
  const customSliderRef = useRef()
  const isFirstSlice = useIsFirstSlice()

  const listName = convertNodeToText(title?.content)
  const [{ slideIndex, setSlideIndex, nextSlide, prevSlide }, setSliderState] =
    useSliderState()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const mobileGridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18Primary,
  })

  const [setRef, handleItemTracker] = useTrackProductCardsList(listName, items)
  return (
    <div
      ref={setRef}
      className={cx(css.PushProducts, className, {
        hasNoCta: !(cta && cta?.children),
      })}>
      <div className={cx(gridStyle, css.top)}>
        {title && isRTFilled(title) && (
          <RichText render={title} className={cx(titleStyle, css.title)} />
        )}

        {cta && cta?.children && (
          <div className={cx(css.ctaWrapper, css.hideOnSmallScreen)}>
            <BlurredCta
              className={cx(css.cta)}
              theme={GlobalThemeColors.Shade1}
              {...cta}
            />
          </div>
        )}
      </div>
      {items?.length > 0 && (
        <>
          <div className={cx(mobileGridStyle, css.body, css.hideOnLargeScreen)}>
            {items?.map((item, index) => {
              return (
                <CardProduct
                  className={cx(css.item, { isSmallItem: item?.isSmall })}
                  key={`card_product_mobile_${index}`}
                  priority={
                    isFirstSlice
                      ? items?.[0]?.isSmall
                        ? index < 2
                        : index < 1
                      : false
                  }
                  handleTracker={(params) => {
                    handleItemTracker({
                      products: [item],
                      event: TRACKING_EVENTS.PRODUCT_CLICK,
                      listPosition: index,
                      ...params,
                    })
                  }}
                  {...item}
                />
              )
            })}
          </div>

          <div
            ref={customSliderRef}
            className={cx(css.body, css.hideOnSmallScreen)}>
            <div className={gridStyle}>
              <div className={cx(css.itemsWrapper, css.sliderContainer)}>
                <div className={css.slides}>
                  <Slider
                    className={cx(css.slider)}
                    customSliderRef={customSliderRef}
                    maxSlideIndexChange={1}
                    setSliderState={setSliderState}
                    snap
                    infinite>
                    {items?.map((item, index) => (
                      <CardProduct
                        {...item}
                        priority={isFirstSlice ? index < 3 : false}
                        className={cx(css.item)}
                        key={`card_product_${index}`}
                        isSmall={false}
                        isFullHeight={true}
                        isFullWidth={false}
                        handleTracker={(params) => {
                          handleItemTracker({
                            products: [item],
                            event: TRACKING_EVENTS.PRODUCT_CLICK,
                            listPosition: index,
                            ...params,
                          })
                        }}
                      />
                    ))}
                  </Slider>
                  <SliderArrows nextSlide={nextSlide} prevSlide={prevSlide} />
                </div>
                {items?.length > 3 && (
                  <div className={css.sliderControls}>
                    <SliderControls
                      length={items?.length}
                      className={css.sliderControlsItem}
                      slideIndex={slideIndex}
                      setSlideIndex={setSlideIndex}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {cta && cta?.children && (
        <div className={cx(gridStyle, css.bottom, css.hideOnLargeScreen)}>
          <div className={css.ctaWrapper}>
            <BlurredCta
              className={cx(css.cta)}
              theme={GlobalThemeColors.Shade1}
              {...cta}
            />
          </div>
        </div>
      )}
    </div>
  )
}

PushProducts.defaultProps = {}

export default PushProducts
