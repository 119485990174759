import {
  StoryblokRichtext,
  StoryblokRichtextContent,
} from 'storyblok-rich-text-react-renderer'

type Truncate<Data extends StoryblokRichtext | StoryblokRichtextContent> = {
  success: boolean
  data: Data
  maxChars: number
}

function truncateText<T extends StoryblokRichtextContent>(
  richtext: T,
  maxChars: number,
  suffix = '...',
): Truncate<T> {
  const count = richtext.text?.replace(' ', '').length || 0
  const diff = maxChars - count

  if (diff <= 0) {
    const text = richtext?.text?.slice(0, maxChars)

    return {
      success: true,
      data: {
        ...richtext,
        ...(text ? { text: `${text.trimEnd()}${suffix}` } : {}),
      },
      maxChars: 0,
    }
  }

  return { success: false, data: richtext, maxChars: diff }
}

function truncateContent<
  T extends StoryblokRichtext | StoryblokRichtextContent,
>(richtext: T, maxChars: number, suffix = '...'): Truncate<T> {
  let success = false
  let count = maxChars

  const content = richtext?.content
    ?.map((item) => {
      if (success) return null

      if ('text' in item) {
        const truncateElement = truncateText(item, count, suffix)
        count = truncateElement.maxChars
        if (truncateElement.success) success = true

        return truncateElement.data
      }

      if ('content' in item) {
        const truncateElement = truncateContent(item, count, suffix)
        count = truncateElement.maxChars
        if (truncateElement.success) success = true

        return truncateElement.data
      }
    })
    .filter(Boolean)

  return {
    success,
    data: { ...richtext, ...(content ? { content } : {}) },
    maxChars: count,
  }
}

export function truncateRichtext<T extends StoryblokRichtext>(
  richtext: T,
  maxChars: number,
  suffix = '...',
): T {
  const truncateElement = truncateContent(richtext, maxChars, suffix)
  return truncateElement.data
}
