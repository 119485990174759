import { Dispatch, SetStateAction, useState } from 'react'

export interface VideoState {
  /**
   * @return {boolean} true if video has been started
   */
  videoStarted: boolean
  /**
   * @return {boolean} true if video is muted
   */
  videoMuted: boolean
  /**
   * @return {boolean} true if video is paused
   */
  videoPaused: boolean
  /**
   * @return {boolean} embed controls config
   */
  videoControls: boolean
  /**
   * @return {boolean} true if video is in viewport
   */
  videoInView: boolean
  /**
   * @return {number} video progress in seconds
   */
  videoProgress: number
  /**
   * @return {number} video duration in seconds
   */
  videoDuration: number
  /**
   * @return {boolean} true if video is hovered
   */
  videoIsHover: boolean
  /**
   * @return {boolean} true if video is ready to play
   */
  videoIsReady: boolean
  /**
   * @return {boolean} true if video is mounted in the DOM
   */
  videoIsDOMReady: boolean
  /**
   * @return {boolean} true if video is buffering
   */
  videoIsLoading: boolean
  /**
   * @return {void} method to toggle play/pause video
   */
  videoHandlePausePlay(): void
  /**
   * @return {void} method to toggle mute/unmute video
   */
  videoHandleMuteUnmute(): void
  /**
   * @param {number} seconds
   * @return {void} method to seek in to video
   */
  videoHandleSeekTo(seconds: number): void
}

/**
 * Returns a videoplayer stateful value, and a function to update it.
 * @returns {Array}
 */
export default function useVideoState(): [
  VideoState,
  Dispatch<SetStateAction<VideoState>>,
] {
  return useState<VideoState>({
    videoStarted: false,
    videoMuted: false,
    videoPaused: true,
    videoControls: true,
    videoInView: false,
    videoProgress: 0,
    videoDuration: 0,
    videoIsHover: false,
    videoIsReady: false,
    videoIsDOMReady: false,
    videoIsLoading: false,
    videoHandlePausePlay: null,
    videoHandleMuteUnmute: null,
    videoHandleSeekTo: null,
  })
}
