import classnames from 'classnames/bind'
import React from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'

import ColumnContact, {
  ColumnContactProps,
} from '~/components/Slices/EditorialTwoColumnsContact/ColumnContact'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialTwoColumnsContactProps {
  className?: string
  items?: ColumnContactProps[]
}

function EditorialTwoColumnsContact({
  className,
  items,
}: EditorialTwoColumnsContactProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return (
    <div className={cx(css.EditorialTwoColumnsContact, gridStyle, className)}>
      {items?.map?.((item, index) => (
        <ColumnContact
          {...item}
          className={css.item}
          key={`column_contact_${index}`}
        />
      ))}
    </div>
  )
}

EditorialTwoColumnsContact.defaultProps = {}

export default EditorialTwoColumnsContact
