import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { forwardRef, ReactNode, useContext, useRef } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { AccordionContext } from '~/components/Abstracts/Accordion'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AccordionPanelProps {
  className?: string
  index?: number
  restoreIndex?: boolean
  head: ((isActive: boolean) => ReactNode) | ReactNode
  body?: ReactNode | ReactNode[]
  theme?: 'light' | 'dark'
  activeClassName?: string
}

const AccordionPanel = (
  {
    className,
    activeClassName,
    index,
    restoreIndex,
    head,
    body,
  }: AccordionPanelProps,
  ref: any,
) => {
  const { currentIndex, setCurrentIndex } = useContext(AccordionContext)

  const indexRef = useRef(null)

  const isActive = index === currentIndex

  const variants = {
    show: { height: 'auto' },
    hide: { height: 0 },
  }

  const handleClick = (index: number) => {
    const newIndex = index === currentIndex ? null : index
    indexRef.current = newIndex
    setCurrentIndex(newIndex)
  }

  useIsomorphicLayoutEffect(() => {
    if (restoreIndex) setCurrentIndex(null)
  }, [restoreIndex])

  return (
    <div
      ref={ref}
      className={cx(
        className,
        css.AccordionPanel,
        isActive ? activeClassName : null,
      )}>
      <button
        type="button"
        className={css.head}
        onClick={() => handleClick(index)}>
        {typeof head === 'function' ? head(isActive) : head}
      </button>
      {body && (
        <m.div
          variants={variants}
          initial={false}
          animate={index === currentIndex ? 'show' : 'hide'}
          transition={{ duration: 0.4, ease: 'easeOut' }}
          className={css.body}>
          {body}
        </m.div>
      )}
    </div>
  )
}

export default forwardRef(AccordionPanel)
