import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Link from '~/components/Abstracts/Link'
import Tag from '~/components/Abstracts/Tag'
import BlurredCta from '~/components/UI/BlurredCta'
import { CardProductProps } from '~/components/UI/CardProduct'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import isTouchScreen from '~/utils/is-touch-screen'

import { PRODUCT } from '~/data/dictionary'
import serializePrice from '~/data/serialize-price'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_RATIO = 3 / 4
const IMAGE_SIZE = [{ ratio: 343 / 375 }]
function CardWorkshop({
  className,
  link,
  name,
  image,
  imageHover,
  priority = false,
  price,
  date,
  location,
}: CardProductProps) {
  const t = useTranslate()
  const locale = useLocale()
  const [isHover, callbacks] = useIsHover()
  const isHovered = isTouchScreen ? false : isHover

  const titleStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: GlobalTextPreset.Title10Primary,
  })

  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  return (
    <Link className={cx(css.CardWorkshop, className)} {...link} {...callbacks}>
      {image && (
        <div className={css.left}>
          <div className={css.cardImages}>
            <Image
              className={cx(css.image)}
              draggable={false}
              priority={priority}
              sizesFromBreakpoints={IMAGE_SIZE}
              ratio={IMAGE_RATIO}
              layout="fill"
              objectFit="cover"
              asPlaceholder
              {...image}
            />

            {imageHover && (
              <AnimatePresence>
                {isHovered && (
                  <m.div
                    className={cx(css.hoverImage)}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { duration: 0.25 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.25 },
                    }}>
                    <Image
                      sizesFromBreakpoints={IMAGE_SIZE}
                      ratio={IMAGE_RATIO}
                      layout="fill"
                      objectFit="cover"
                      draggable="false"
                      {...imageHover}
                    />
                  </m.div>
                )}
              </AnimatePresence>
            )}
          </div>
        </div>
      )}
      <div className={css.right}>
        <div className={css.content}>
          {name && <h3 className={cx(css.title, titleStyle)}>{name}</h3>}
          {price && (
            <div className={cx(css.price, priceStyle)}>
              {t(PRODUCT.PRICE_FROM)}&nbsp;
              <Tag
                className={cx(css.priceTag)}
                textPreset={GlobalTextPreset.Label12Secondary}>
                {serializePrice(locale, price.currencyCode, price.amount)}
              </Tag>
            </div>
          )}
        </div>
        {(date || location) && (
          <div className={css.infos}>
            {date && (
              <BlurredCta
                className={cx(css.info)}
                theme={GlobalThemeColors.Shade1}
                isDiv>
                {date}
              </BlurredCta>
            )}
            {location && (
              <BlurredCta
                className={cx(css.info)}
                theme={GlobalThemeColors.Shade1}
                isDiv>
                {location}
              </BlurredCta>
            )}
          </div>
        )}
      </div>
    </Link>
  )
}

CardWorkshop.defaultProps = {}

export default CardWorkshop
