import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import { LinkProps } from '~/components/Abstracts/Link'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import { convertNodeToText } from '~/components/Abstracts/Storyblok/as-text'
import BlurredCta from '~/components/UI/BlurredCta'
import { CardProductProps } from '~/components/UI/CardProduct'
import SliderProducts, {
  SliderProductsProps,
} from '~/components/UI/SliderProducts'

import { useStyle } from '~/providers/StyleProvider'

import useTrackProductCardsList from '~/hooks/tracking/useTrackProductCardsList'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProductTitleSliderProps {
  withArrows?: boolean
  className?: string
  separatorClassName?: string
  title?: RichTextBlock | string
  text?: RichTextBlock
  titleTextPreset?:
    | GlobalTextPreset.Title18_24Primary
    | GlobalTextPreset.Title18Primary
  sliderItems?: CardProductProps[]
  sliderProps?: SliderProductsProps
  withControls?: boolean
  sliderLayout?: SliderProductsProps['layout']
  cta?: LinkProps
}

function ProductTitleSlider({
  className,
  separatorClassName,
  title,
  text,
  titleTextPreset,
  sliderItems,
  sliderProps,
  withArrows = true,
  withControls = false,
  sliderLayout,
  cta,
}: ProductTitleSliderProps) {
  const isFirstSlice = useIsFirstSlice()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: titleTextPreset,
  })
  const richTextTextStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Secondary,
  })
  const titleClassNames = cx(css.title, titleStyle)
  const listName =
    typeof title === 'string' ? title : convertNodeToText(title?.content)

  const [setRef, handleItemTracker] = useTrackProductCardsList(
    listName,
    sliderItems,
  )

  return (
    <div
      className={cx(css.ProductTitleSlider, className, {
        ctaHeader: title && cta,
      })}
      ref={setRef}>
      {/*<div className={cx(css.separator, separatorClassName, gridStyle)}></div>*/}
      <div className={gridStyle}>
        {title && (
          <div className={cx(css.contentContainer)}>
            <div className={cx(css.topRow)}>
              {typeof title === 'string' ? (
                <h3 className={cx(css.title, titleClassNames)}>{title}</h3>
              ) : (
                <RichText
                  render={title}
                  className={cx(css.title, titleClassNames)}
                />
              )}

              {cta && (
                <div className={cx(css.cta, css.hideOnSmallScreen)}>
                  <BlurredCta {...cta} isBig />
                </div>
              )}
            </div>
            {text && (
              <RichText
                render={text}
                className={cx(richTextTextStyle, css.text)}
              />
            )}
          </div>
        )}
      </div>
      {sliderItems && sliderItems?.length > 0 && (
        <SliderProducts
          withArrows={withArrows}
          className={css.slider}
          items={sliderItems}
          handleItemTracker={handleItemTracker}
          withControls={withControls}
          isInFirstSlice={isFirstSlice}
          {...sliderProps}
          {...(sliderLayout ? { layout: sliderLayout } : {})}
        />
      )}
      {cta && (
        <div className={cx(css.cta, css.hideOnLargeScreen)}>
          <BlurredCta {...cta} isBig />
        </div>
      )}
    </div>
  )
}

ProductTitleSlider.defaultProps = {
  titleTextPreset: GlobalTextPreset.Title18_24Primary,
}

export default ProductTitleSlider
