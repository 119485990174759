import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'

import { CardProductProps } from '~/components/UI/CardProduct'
import MagazineItem from '~/components/UI/MagazineItem'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BlogGridProps {
  className?: string
  backgroundColor?: string
  items?: CardProductProps[]
}
const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 / 4 }, { ratio: 1 }]
function BlogGrid({ className, backgroundColor, items }: BlogGridProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })

  return (
    <div
      style={{ backgroundColor }}
      className={cx(css.BlogGrid, gridStyle, className)}>
      {items?.map((item, index) => {
        const imgProps = {
          sizesFromBreakpoints: IMAGE_SIZES,
        }
        return (
          <MagazineItem
            key={index}
            className={css.item}
            {...item}
            imgProps={imgProps}
          />
        )
      })}
    </div>
  )
}

export default BlogGrid
