import classnames from 'classnames/bind'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText from '~/components/Abstracts/RichText'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'
import { truncateRichtext } from '~/utils/truncate-rich-text'

import { CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialTextProps {
  className?: string
  text?: StoryblokRichtext
  isCentered?: boolean
  hasBottomSeparator?: boolean
  hasTopSeparator?: boolean
  truncateFrom?: number
}

function EditorialText({
  className,
  text,
  isCentered,
  hasBottomSeparator,
  hasTopSeparator,
  truncateFrom,
}: EditorialTextProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const truncatedText = truncateFrom
    ? truncateRichtext(text, truncateFrom, '...')
    : text

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.RichTextEditorial,
  })

  const t = useTranslate()
  const [seeMore, setSeeMore] = useState(truncateFrom ? false : true)

  return (
    <div
      className={cx(css.EditorialText, className, gridStyle, {
        isCentered,
        hasTopSeparator,
        hasBottomSeparator,
      })}>
      {isRTFilled(text) && (
        <div className={css.container}>
          <RichText
            className={cx(css.text, css.content, textStyle)}
            render={seeMore ? text : truncatedText}
          />
        </div>
      )}
      {Boolean(truncateFrom) && (
        <div className={cx(css.ctaContainer)}>
          <InlineCta onClick={() => setSeeMore(!seeMore)} className={css.cta}>
            {!seeMore ? t(CTA.READ_MORE) : t(CTA.READ_LESS)}
          </InlineCta>
        </div>
      )}
    </div>
  )
}

EditorialText.defaultProps = {}

export default EditorialText
