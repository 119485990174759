import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { TRACKING_EVENTS } from '~/lib/constants'
import { STORYBLOK_COMPONENTS } from '~/lib/storyblok-types'

import PushGridItem, {
  PushGridItemData,
  PushGridItemProps,
} from '~/components/Abstracts/PushGridItem'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'

import { useStyle } from '~/providers/StyleProvider'

import useTrackPromotionListCardList from '~/hooks/tracking/useTrackPromotionList'
import useIsFirstSlice from '~/hooks/useIsFirstSlice'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PushGridProps {
  className?: string
  hasTopSeparator?: boolean
  hasBottomSeparator?: boolean
  title?: RichTextBlock
  items?: PushGridItemProps[]
}

function PushGrid({
  className,
  title,
  items,
  hasBottomSeparator,
  hasTopSeparator,
}: PushGridProps) {
  const isFirstSlice = useIsFirstSlice()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24Primary,
  })

  const handleClick = (item: PushGridItemProps) => {
    if (onClickOnPromotion) {
      onClickOnPromotion({
        event: TRACKING_EVENTS.PROMOTION_CLICK,
        items: [item],
      })
    }
  }

  const isCategoryPushItem = (item: PushGridItemData) => {
    return (
      item?.type === STORYBLOK_COMPONENTS.PUSH_GRID_CATEGORY_ITEM ||
      item?.type === STORYBLOK_COMPONENTS.PUSH_GRID_ITEM
    )
  }

  const [setRef, onClickOnPromotion] = useTrackPromotionListCardList(
    items?.filter((item) => isCategoryPushItem(item)),
  )

  const lastPushGridItemIsFullWidth =
    items?.[items?.length - 1]?.layout === 'full'

  const hasOnlyOneItemAndFullWidth =
    items?.length === 1 && lastPushGridItemIsFullWidth

  return (
    <div
      className={cx(css.PushGrid, className, {
        hasBottomSeparator,
        hasTopSeparator,
        lastPushGridItemIsFullWidth,
        isFirstSlice,
        hasOnlyOneItemAndFullWidth,
      })}
      ref={setRef}>
      {hasTopSeparator && <div className={cx(css.separator, gridStyle)}></div>}
      {title && isRTFilled(title) && (
        <div className={cx(gridStyle, css.top)}>
          <RichText render={title} className={cx(titleStyle, css.title)} />
        </div>
      )}
      {items && items?.length > 0 && (
        <div className={css.items}>
          {items?.map((item, index) => {
            const isHalf = item?.layout === 'half'

            return (
              <PushGridItem
                {...item}
                key={`push_grid_${index}`}
                isInGrid={isHalf}
                isFirstItem={
                  isFirstSlice && (index === 0 || (index === 1 && isHalf))
                }
                className={cx(css.item, {
                  isFull: !isHalf,
                  isHalf,
                })}
                {...(isCategoryPushItem(item) && {
                  onClick: () => handleClick(item),
                })}
              />
            )
          })}
        </div>
      )}
      {hasBottomSeparator && (
        <div className={cx(css.separator, gridStyle)}></div>
      )}
    </div>
  )
}

PushGrid.defaultProps = {}

export default PushGrid
