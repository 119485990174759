import classnames from 'classnames/bind'
import { useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import Link from '~/components/Abstracts/Link'
import Ratio from '~/components/Abstracts/Ratio'
import SliderControls from '~/components/Abstracts/SliderControls'
import { Media, MediaWithLink } from '~/components/MediaGrid'
import { CardProductProps } from '~/components/UI/CardProduct'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import MagazineItem from '~/components/UI/MagazineItem'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGES_SIZES = {
  FIRST: [{ breakpoint: 'md', ratio: 1 / 4 }, { ratio: 1 }],
  ITEMS: [{ breakpoint: 'md', ratio: 1 / 4 }, { ratio: 1 / 2 }],
}

export interface PushSliderProps {
  className?: string
  backgroundColor?: string
  media: MediaWithLink[]
  title?: string
  cta?: InlineCtaProps
  items?: CardProductProps[]
}

function PushSlider({
  className,
  backgroundColor,
  media,
  title,
  cta,
  items,
}: PushSliderProps) {
  const customSliderRef = useRef()

  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const mobileGridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label18Secondary,
    textStyling: [GlobalTextStyling.UpperCase, GlobalTextStyling.Bold],
  })

  return (
    <section>
      <div
        style={{ backgroundColor }}
        className={cx(css.PushSliderDesktop, css.hideOnSmallScreen, className)}>
        <>
          <div className={cx(css.header, gridStyle)}>
            {title && <h3 className={cx(css.title, titleStyle)}>{title}</h3>}

            <InlineCta
              className={cx(css.cta)}
              theme={GlobalThemeColors.Black}
              textPreset={GlobalTextPreset.Cta10Primary}
              {...cta}
            />

            {media && media?.length > 0 && (
              <Ratio ratio={330 / 495} className={cx(css.media)}>
                {(className) =>
                  media?.[0]?.link ? (
                    <Link {...media?.[0].link}>
                      <Media
                        className={cx(className, css.mediaItem)}
                        {...media?.[0]}
                      />
                    </Link>
                  ) : (
                    <Media
                      className={cx(className, css.mediaItem)}
                      {...media?.[0]}
                    />
                  )
                }
              </Ratio>
            )}

            <div className={css.sliderContainer} ref={customSliderRef}>
              <Slider
                className={cx(css.slider)}
                customSliderRef={customSliderRef}
                setSliderState={setSliderState}
                autoIsSlider={false}
                snap
                infinite>
                {items?.map((item, index) => {
                  const imgProps = {
                    sizesFromBreakpoints:
                      index === 0 ? IMAGES_SIZES.FIRST : IMAGES_SIZES.ITEMS,
                  }

                  return (
                    <MagazineItem
                      className={css.sliderItem}
                      key={index}
                      imgProps={imgProps}
                      {...item}
                    />
                  )
                })}
              </Slider>
            </div>
            <div className={css.sliderControls}>
              <SliderControls
                length={items?.length + 1}
                className={css.mobileSliderControls}
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
              />
            </div>
          </div>
        </>
      </div>

      <div
        className={cx(
          css.PushSliderMobile,
          css.hideOnLargeScreen,
          mobileGridStyle,
          className,
        )}>
        <div className={css.header}>
          {title && <h3 className={titleStyle}>{title}</h3>}
        </div>

        <Ratio ratio={330 / 495} className={css.image}>
          {(className) =>
            media?.[0]?.link ? (
              <Link {...media?.[0].link}>
                <Media className={className} {...media?.[0]} />
              </Link>
            ) : (
              <Media className={className} {...media?.[0]} />
            )
          }
        </Ratio>

        <div className={css.content}>
          {items?.map((item, index) => {
            const imgProps = {
              sizesFromBreakpoints:
                index === 0 ? IMAGES_SIZES.FIRST : IMAGES_SIZES.ITEMS,
            }

            return (
              <MagazineItem
                key={index}
                className={css.item}
                {...item}
                imgProps={imgProps}
              />
            )
          })}
        </div>

        <div className={css.footer}>
          <InlineCta
            className={cx(css.cta)}
            theme={GlobalThemeColors.Black}
            textPreset={GlobalTextPreset.Cta10Primary}
            {...cta}
          />
        </div>
      </div>
    </section>
  )
}

export default PushSlider
