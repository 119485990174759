export enum GlobalBreakpoints {
  XXXS = 'xxxs',
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  L = 'l',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}
