import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import { PlayIcon } from '~/components/Abstracts/Icons'
import { VideoState } from '~/components/Abstracts/VideoPlayer/hooks/useVideoState'
import Image, { ImageProps, Sizes } from '~/components/UI/Image'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface VideoPlayerMockProps
  extends Pick<VideoState, 'videoHandlePausePlay'> {
  className?: string
  image?: ImageProps
  ratio?: number
  sizesFromBreakpoints?: Sizes
  children?: ReactNode | ReactNode[]
  icon?: ReactNode | ReactNode[]
  iconClassName?: string
  hide?: boolean
}

const IMAGE_RATIO = 16 / 9
const IMAGE_SIZES = [{ ratio: 24 / 24 }]

function VideoPlayerMock({
  className,
  image,
  ratio,
  sizesFromBreakpoints,
  children,
  hide,
  videoHandlePausePlay,
  icon,
  iconClassName,
}: VideoPlayerMockProps) {
  return (
    <div
      className={cx(css.VideoPlayerMock, className, { hide })}
      onClick={videoHandlePausePlay}>
      <div>
        <Image
          layout="fill"
          objectFit="cover"
          className={css.image}
          sizesFromBreakpoints={sizesFromBreakpoints || IMAGE_SIZES}
          ratio={ratio || IMAGE_RATIO}
          asPlaceholder
          {...image}
        />
        <div className={cx(css.icon, iconClassName)}>
          {icon ? icon : <PlayIcon fill="#FFFFFF" width="40" height="40" />}
        </div>
      </div>
      {children}
    </div>
  )
}

VideoPlayerMock.defaultProps = {
  visible: true,
}

export default VideoPlayerMock
