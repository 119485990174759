import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Accordion from '~/components/Abstracts/Accordion'
import AccordionPanel from '~/components/Abstracts/Accordion/AccordionPanel'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import { ArrowIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type HeadlineTextPreset = GlobalTextPreset.Title10Primary
export type BodyTextPreset =
  | GlobalTextPreset.RichTextEditorial
  | GlobalTextPreset.Text12Secondary

export interface AccordionItemProps {
  headline?: RichTextBlock
  text?: RichTextBlock
}

export interface AccordionProps {
  className?: string
  items?: AccordionItemProps[]
  headlineTextPreset?: HeadlineTextPreset
  bodyTextPreset?: BodyTextPreset
  index?: number
  activeClassName?: string
  layout?: 'default' | 'productHeader'
  color?: GlobalThemeColors.Gray1ToBlack | GlobalThemeColors.Black
}

function AccordionItems({
  className,
  items,
  color = GlobalThemeColors.Black,
  layout = 'default',
  headlineTextPreset,
  bodyTextPreset,
}: AccordionProps) {
  const headlineStyle = useStyle({
    textPreset: headlineTextPreset,
    textStyling: GlobalTextStyling.UpperCase,
    color,
  })

  const textStyle = useStyle({
    textPreset: bodyTextPreset,
    color,
  })

  const headStyle = useStyle({
    color,
  })

  return (
    <div className={cx(css.Accordion, className, layout)}>
      {items?.length > 0 && (
        <Accordion>
          {items?.map(({ headline, text }, key) => (
            <AccordionPanel
              className={cx(css.panel)}
              activeClassName={cx({ isActive: true })}
              key={`item_${key}`}
              index={key}
              head={(isActive: boolean) => (
                <div className={cx(css.panelHead, headStyle, { isActive })}>
                  <RichText
                    className={cx(css.headline, headlineStyle)}
                    render={headline}
                  />
                  <div className={cx(css.icon)}>
                    <ArrowIcon className={css.iconItem} />
                  </div>
                </div>
              )}
              body={
                <div className={cx(css.panelBody)}>
                  <RichText className={(css.text, textStyle)} render={text} />
                </div>
              }
            />
          ))}
        </Accordion>
      )}
    </div>
  )
}

AccordionItems.defaultProps = {
  index: 0,
}

export default AccordionItems
