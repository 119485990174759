import { useCallback, useEffect, useState } from 'react'

export default function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  const copyToClipboard = useCallback((text) => {
    navigator?.clipboard?.writeText(text).then(
      () => {
        setIsCopied(true)
      },
      () => {
        setIsErrored(true)
      },
    )
  }, [])

  useEffect(() => {
    if (isCopied || isErrored) {
      setTimeout(() => {
        setIsCopied(false)
        setIsErrored(false)
      }, 3000)
    }
  }, [isCopied, isErrored])

  return {
    copyToClipboard,
    isCopied,
    isErrored,
  }
}
