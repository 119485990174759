import classnames from 'classnames/bind'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import AccordionItems, {
  AccordionItemProps,
} from '~/components/UI/AccordionItems'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EditorialAccordionsProps {
  className?: string
  title?: RichTextBlock
  accordionsGroups?: [
    {
      title?: RichTextBlock
      accordions?: AccordionItemProps[]
    },
  ]
}

function EditorialAccordions({
  className,
  title,
  accordionsGroups,
}: EditorialAccordionsProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24Primary,
  })
  const groupTitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18Primary,
  })

  return (
    <div className={cx(css.EditorialAccordions, className)}>
      <div className={gridStyle}>
        {title && (
          <RichText render={title} className={cx(css.title, titleStyle)} />
        )}
      </div>
      {accordionsGroups?.length > 0 && (
        <div className={css.accordionGroups}>
          {accordionsGroups.map((accordionGroup, index) => (
            <div
              className={css.accordionGroupItem}
              key={`accordion_item_${index}`}>
              {accordionGroup?.title && (
                <RichText
                  render={accordionGroup.title}
                  className={cx(css.accordionGroupTitle, groupTitleStyle)}
                />
              )}
              <AccordionItems
                className={css.accordionGroupItem}
                headlineTextPreset={GlobalTextPreset.Title10Primary}
                bodyTextPreset={GlobalTextPreset.RichTextEditorial}
                items={accordionGroup?.accordions}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

EditorialAccordions.defaultProps = {}

export default EditorialAccordions
